"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContractUtils = void 0;
const common_1 = require("../common");
const number_utils_1 = require("./number.utils");
class ContractUtils {
  static getRiskLevelDisplay(riskScore, complianceThreshold) {
    if (riskScore > complianceThreshold) {
      return {
        display: 'Yes',
        color: '#fc8f3b'
      };
    }
    return {
      display: 'No',
      color: '#009900'
    };
  }
  static getTotalMargin(contract, includeBufferMargin = true, includeParentBufferMargin = false, includeMatrixAgentBufferMargin = false, unit) {
    let totalMargin = contract.margin;
    if (includeParentBufferMargin && contract.commissionSplitBufferMargin) {
      totalMargin = Math.round((totalMargin + contract.commissionSplitBufferMargin) * 1e12) / 1e12;
    }
    if (includeBufferMargin && contract.bufferMargin) {
      totalMargin = Math.round((totalMargin + contract.bufferMargin) * 1e12) / 1e12;
    }
    if (includeMatrixAgentBufferMargin && contract.matrixAgentBufferMargin) {
      totalMargin = Math.round((totalMargin + contract.matrixAgentBufferMargin) * 1e12) / 1e12;
    }
    totalMargin = number_utils_1.NumberUtils.rateToUnits(totalMargin, unit);
    return totalMargin;
  }
  static shouldIncludeBufferMargin(contract) {
    return contract.status === common_1.CONSTANTS.statuses.acomp && !!contract.awardDate || common_1.CONSTANTS.statuses.signed === contract.status || !!contract.confirmationDate;
  }
}
exports.ContractUtils = ContractUtils;
