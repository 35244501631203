"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NumberUtils = void 0;
const tslib_1 = require("tslib");
const crypto = tslib_1.__importStar(require("crypto"));
class NumberUtils {
  static thermToMCF(value) {
    return value / 10;
  }
  static ratePerThermToRatePerMcf(value) {
    return value * 10;
  }
  static mcfToTherm(value) {
    return value * 10;
  }
  static ratePerMcfToRatePerTherm(value) {
    return value / 10;
  }
  static thermToCcf(value) {
    return value / 1;
  }
  static ratePerThermToRatePerCcf(value) {
    return value * 1;
  }
  static kwhToMwh(value) {
    return value / 1000;
  }
  static ratePerKwhToRatePerMwh(value) {
    return value * 1000;
  }
  static mwhToKwh(value) {
    return value * 1000;
  }
  static ratePerMwhToRatePerKwh(value) {
    return value / 1000;
  }
  static ccfToTherm(value) {
    return value * 1;
  }
  static ratePerCcfToRatePerTherm(value) {
    return value / 1;
  }
  static m3ToCcf(value) {
    return value / 2.83;
  }
  static ratePerM3ToRatePerCcf(value) {
    return value * 2.83;
  }
  static ccfToM3(value) {
    return value * 2.83;
  }
  static ratePerCcfToRatePerM3(value) {
    return value / 2.83;
  }
  static m3ToGasKWh(value) {
    return value * (40 / 3.6);
  }
  static ratePerM3ToRatePerGasKWh(value) {
    return value * (3.6 / 40);
  }
  static gasKWhToM3(value) {
    return value * (3.6 / 40);
  }
  static ratePerGasKWhToRatePerM3(value) {
    return value * (40 / 3.6);
  }
  static thermToGj(value) {
    return value / 9.48;
  }
  static ratePerThermToRatePerGj(value) {
    return value * 9.48;
  }
  static ratePerGjToRatePerTherm(value) {
    return value / 9.48;
  }
  static gjToTherm(value) {
    return value * 9.48;
  }
  static m3ToTherm(value) {
    return this.ccfToTherm(this.m3ToCcf(value));
  }
  static ratePerM3ToRatePerTherm(value) {
    return this.ratePerCcfToRatePerTherm(this.ratePerM3ToRatePerCcf(value));
  }
  static thermToM3(value) {
    return this.ccfToM3(this.thermToCcf(value));
  }
  static ratePerThermToRatePerM3(value) {
    return this.ratePerCcfToRatePerM3(this.ratePerThermToRatePerCcf(value));
  }
  static thermToDecatherm(value) {
    return value / 10;
  }
  static ratePerThermToRatePerDecatherm(value) {
    return value * 10;
  }
  static decaThermToTherm(value) {
    return value * 10;
  }
  static ratePerDecaThermToRatePerTherm(value) {
    return value / 10;
  }
  static thermToGasKWh(value) {
    return this.m3ToGasKWh(this.thermToM3(value));
  }
  static ratePerThermToRatePerGasKWh(value) {
    return this.ratePerM3ToRatePerGasKWh(this.ratePerThermToRatePerM3(value));
  }
  static gasKWhToTherm(value) {
    return this.m3ToTherm(this.gasKWhToM3(value));
  }
  static ratePerGasKWhToRatePerTherm(value) {
    return this.ratePerM3ToRatePerTherm(this.ratePerGasKWhToRatePerM3(value));
  }
  static amountToUnits(amount, units) {
    let multiplier = 1;
    switch (units?.toLowerCase()) {
      case 'mcf':
        multiplier *= this.thermToMCF(1);
        break;
      case 'ccf':
        multiplier *= this.thermToCcf(1);
        break;
      case 'gj':
        multiplier *= this.thermToGj(1);
        break;
      case 'm3':
        multiplier *= this.thermToM3(1);
        break;
      case 'dekatherm':
      case 'decatherm':
      case 'dth':
        multiplier *= this.thermToDecatherm(1);
        break;
      case 'kwh (gas)':
        multiplier *= this.thermToGasKWh(1);
        break;
      case 'mwh':
        multiplier *= this.kwhToMwh(1);
        break;
    }
    return Math.round(Number(amount) * multiplier * 1e12) / 1e12;
  }
  static amountToTherms(amount, units) {
    let multiplier = 1;
    switch (units?.toLowerCase()) {
      case 'mcf':
        multiplier *= this.mcfToTherm(1);
        break;
      case 'ccf':
        multiplier *= this.ccfToTherm(1);
        break;
      case 'gj':
        multiplier *= this.gjToTherm(1);
        break;
      case 'm3':
        multiplier *= this.m3ToTherm(1);
        break;
      case 'dekatherm':
      case 'decatherm':
      case 'dth':
        multiplier *= this.decaThermToTherm(1);
        break;
      case 'kwh (gas)':
        multiplier *= this.gasKWhToTherm(1);
        break;
      case 'mwh':
        multiplier *= this.mwhToKwh(1);
        break;
    }
    return Math.round(Number(amount) * multiplier * 1e12) / 1e12;
  }
  static unitsToAmount(amount, units) {
    return NumberUtils.amountToTherms(amount, units);
  }
  static rateToUnits(amount, units) {
    let multiplier = 1;
    switch (units?.toLowerCase()) {
      case 'mcf':
        multiplier *= this.ratePerThermToRatePerMcf(1);
        break;
      case 'ccf':
        multiplier *= this.ratePerThermToRatePerCcf(1);
        break;
      case 'gj':
        multiplier *= this.ratePerThermToRatePerGj(1);
        break;
      case 'm3':
        multiplier *= this.ratePerThermToRatePerM3(1);
        break;
      case 'dekatherm':
      case 'decatherm':
      case 'dth':
        multiplier *= this.ratePerThermToRatePerDecatherm(1);
        break;
      case 'kwh (gas)':
        multiplier *= this.ratePerThermToRatePerGasKWh(1);
        break;
      case 'mwh':
        multiplier *= this.ratePerKwhToRatePerMwh(1);
        break;
    }
    return Math.round(Number(amount) * multiplier * 1e12) / 1e12;
  }
  static rateToTherms(amount, units) {
    let multiplier = 1;
    switch (units?.toLowerCase()) {
      case 'mcf':
        multiplier *= this.ratePerMcfToRatePerTherm(1);
        break;
      case 'ccf':
        multiplier *= this.ratePerCcfToRatePerTherm(1);
        break;
      case 'gj':
        multiplier *= this.ratePerGjToRatePerTherm(1);
        break;
      case 'm3':
        multiplier *= this.ratePerM3ToRatePerTherm(1);
        break;
      case 'dekatherm':
      case 'decatherm':
      case 'dth':
        multiplier *= this.ratePerDecaThermToRatePerTherm(1);
        break;
      case 'kwh (gas)':
        multiplier *= this.ratePerGasKWhToRatePerTherm(1);
        break;
      case 'mwh':
        multiplier *= this.ratePerMwhToRatePerKwh(1);
        break;
    }
    return Math.round(Number(amount) * multiplier * 1e12) / 1e12;
  }
  static unitsToRate(amount, units) {
    return NumberUtils.rateToTherms(amount, units);
  }
  static annualUsageToTherms(annualUsage, units) {
    return this.amountToTherms(annualUsage, units);
  }
  static annualUsageToUnits(annualUsage, units) {
    return this.amountToUnits(annualUsage, units);
  }
  static bytesToKilobytes(value) {
    return Number(value) / 1024;
  }
  static bytesToMegabytes(value) {
    return this.bytesToKilobytes(value) / 1024;
  }
  static bytesToGigabytes(value) {
    return this.bytesToMegabytes(value) / 1024;
  }
  static round(value, decimals = 2) {
    let expNotationValue = String(value).includes('e') ? `${value}` : `${value}e${decimals}`;
    return Number(Math.round(Number(expNotationValue)) + `e-${decimals}`);
  }
  static random() {
    return crypto.randomBytes(4).readUInt32LE(0) / 0x100000000;
  }
  static subtractFloatingPoint(minuend, subtrahend) {
    return (minuend * 1e12 - subtrahend * 1e12) / 1e12;
  }
  static linearRegression(x, y) {
    let sumX = 0,
      sumY = 0,
      sumXY = 0,
      sumXX = 0,
      sumYY = 0;
    let n = y.length;
    for (let i = 0; i < y.length; i++) {
      sumX += x[i];
      sumY += y[i];
      sumXY += x[i] * y[i];
      sumXX += x[i] * x[i];
      sumYY += y[i] * y[i];
    }
    return {
      slope: (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX),
      r2: Math.pow((n * sumXY - sumX * sumY) / Math.sqrt((n * sumXX - sumX * sumX) * (n * sumYY - sumY * sumY)), 2)
    };
  }
  static weightedAverage(nums, weights) {
    const [sum, weightSum] = weights.reduce((acc, w, i) => {
      acc[0] = acc[0] + nums[i] * w;
      acc[1] = acc[1] + w;
      return acc;
    }, [0, 0]);
    return sum / weightSum;
  }
}
exports.NumberUtils = NumberUtils;
