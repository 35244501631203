import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { Subject } from 'rxjs';

import { PromoCode } from 'src/app/shared/models/promo-code';
import { GraphqlService } from '../../grahpql/services';

@Injectable({
	providedIn: 'root',
})
export class AppStateService {
	public isPlatformBrowser: boolean;

	public onReady = new Subject<{ app: boolean }>();
	public promoCode: PromoCode;

	constructor(
		@Inject(PLATFORM_ID) private platformId: object,
		private graphqlService: GraphqlService,
	) {
		this.isPlatformBrowser = isPlatformBrowser(this.platformId);
	}

	public setReady(ready: { app: boolean }): void {
		this.onReady.next(ready);
	}

	public async setPromoCode(isPkd: boolean, salesCode: string): Promise<void> {
		if (this.promoCode) {
			return;
		}

		const result = await this.graphqlService.query<{ promoCodes: { message: PromoCode[] } }>(
			`
			query ECommerceAgent($salesCode: String) {
				${isPkd ? 'promoCodes(criteria: {isActive: true, slug: $salesCode}) {' : 'promoCodes(criteria: {isActive: true, slugOrDefault: true, slug: $salesCode}) {'}
					message {
						id
						isDefault
						slug
						agent {
							agentId
							parentId
							name
							eCommerceWebsite {
								contactPhoneNumber
								contactEmail
								contactAddress1
								contactAddress2
								contactCity
								contactState {
									stateLong
									stateShort
								}
								contactZipCode
								contactPhoneNumberCountry {
									id
									code
								}
								heroLogoAttachment {
									contentType
									contentSubType
								}
								heroLogoAttachmentBase64 {
									response
								}
								navbarLogoAttachment {
									contentType
									contentSubType
								}
								navbarLogoAttachmentBase64 {
									response
								}
								footerLogoAttachment {
									contentType
									contentSubType
								}
								footerLogoAttachmentBase64 {
									response
								}
							}
							logins {
								isActive
								emails {
									email
								}
							}
						}
					}
				}
			}
		`,
			{ salesCode },
		);

		// get parent default, then try own default
		this.promoCode =
			result.data.promoCodes.message.find(p => p.isDefault && !p.agent.parentId) ?? result.data.promoCodes.message.find(p => p.isDefault);

		let link = document.querySelector("link[rel~='icon']") as any;
		if (!link) {
			link = document.createElement('link') as any;
			link.rel = 'icon';
			document.head.appendChild(link);
		}
		link.href = 'https://static.powerkiosk.com/test/pku-381/favicon.ico';
	}
}
