"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RfqSessionBidUtils = void 0;
const common_1 = require("../common");
class RfqSessionBidUtils {
  static convertBidToInternalUnits(amount, serviceTypeId, units, isMultiply = true) {
    const factor = common_1.CONSTANTS.units[serviceTypeId]?.find(u => u.text === units)?.factor || 1;
    if (isMultiply) {
      return Math.round(amount * factor * 1e12) / 1e12;
    } else {
      return Math.round(amount / factor * 1e12) / 1e12;
    }
  }
}
exports.RfqSessionBidUtils = RfqSessionBidUtils;
