import { ApplicationConfig } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../../../environments/environment';

export const graphqlProvider: ApplicationConfig['providers'] = [
	Apollo,
	{
		provide: APOLLO_OPTIONS,
		useFactory: (httpLink: HttpLink) => {
			return {
				cache: new InMemoryCache({
					addTypename: false,
				}),
				link: httpLink.create({
					uri: `${environment.graphQLEndpoint}/graphql`,
				}),
				defaultOptions: {
					query: {
						fetchPolicy: 'no-cache',
					},
					mutate: {
						fetchPolicy: 'no-cache',
					},
				},
			};
		},
		deps: [HttpLink],
	},
];
